import { Button, Label, TextInput } from 'flowbite-react'
import React, { FormEvent, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import CButton from '../components/base-components/CButton'
import { BackendAPI } from '../constants'
import { toast } from 'react-toastify'
import { AxiosError } from 'axios'

const Login = () => {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')

  const handleLogin = async () => {
    setIsLoading(true)
    try {
      const response = await BackendAPI.post('/auth/login', { email: username, password: password })
      if (response.status == 200) {
        localStorage.setItem('heygen_key', response.data.heygen_key)
        localStorage.setItem('deepgram_key', response.data.deepgram_key)
        localStorage.setItem('user', response.data.email)
        setIsLoading(false)
        navigate('/')
      } else {
        toast.error(`Unable to login. Code ${response.status}`)
      }
    } catch (error: any) {
      toast.error(error.message)
    }
    setIsLoading(false)
  }

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    if (!username || !password) {
      setError('Please enter both email and password')
      return
    }
    handleLogin()
  }

  return (
    <div className="flex flex-row h-screen w-full overflow-hidden">
      <div className="bg-[#001D3D] h-full w-3/5">
        <div className="w-full h-full flex relative items-center justify-center">
          <div className="animate-one absolute top-0 right-0 mt-32 -mr-12 rounded-full w-[15vw] h-[15vw] bg-[#802DC8]/20"></div>
          <div className="animate-two absolute top-0 -mt-16 left-[30%] -mr-12 rounded-full w-64 h-64 bg-[#FF4F58]/20"></div>
          <div className="animate-three absolute top-0 right-0 -mt-12 -mr-10 rounded-2xl w-[25vw] h-[10vw] bg-[#FF4F58]/20"></div>
          <div className="animate-one absolute bottom-32 -left-2 -mr-0 rounded-2xl w-[12vw] h-[40vh] bg-[#802DC8]/20"></div>
          <div className="animate-four absolute -bottom-10 left-[10vw] text-[#FF4F58]/20">
            <svg width="222" height="199" viewBox="0 0 222 199" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M197.774 0.26234C216.25 0.262344 227.796 20.2624 218.559 36.2623L131.956 186.262C122.719 202.262 99.6247 202.262 90.3871 186.262L3.78457 36.2623C-5.45304 20.2623 6.09399 0.262344 24.5692 0.262345L197.774 0.26234Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </div>
      </div>
      <div className="bg-white relative h-full w-2/5 flex flex-col justify-center p-16">
        <img src="/assets/logo.svg" className="w-36" />
        <div className="text-xl mt-8">
          Welcome to <span className="font-bold">Totogi</span>
        </div>
        <div className="text-gray-500 my-8 text-lg">Launch BSS apps in minutes, without IT.</div>
        <form className="w-full" onSubmit={handleSubmit}>
          <Label htmlFor="username" className="text-lg font-normal" value="Username" />
          <TextInput
            id="username"
            type="text"
            color={error ? 'failure' : 'white'}
            className="my-4"
            placeholder="Username*"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
          <Label htmlFor="password" className="text-lg font-normal" value="Password" />
          <TextInput
            id="password"
            type="password"
            color={error ? 'failure' : 'white'}
            helperText={<span>{error}</span>}
            className="my-4"
            placeholder="Password*"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <CButton type="primary" classname="py-2 my-4" onClick={handleLogin} isLoading={isLoading}>
            SIGN IN
          </CButton>
          <CButton type="tertiary">Forgot password?</CButton>
        </form>
      </div>
    </div>
  )
}

export default Login
