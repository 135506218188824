import React, { useEffect, useRef, useState } from 'react'
import { MdOutlineSend } from 'react-icons/md'
import { MdOutlineFileUpload } from 'react-icons/md'
import { isBotMessage, isUserMessage } from './utilities'
import { IMessage } from './interfaces/IMessages'
import { IChatbotProps } from './interfaces/IChatbot'
import UserChatMessage from './UserChatMessage'
import BotChatMessage from './BotChatMessage'
import { useChatbot } from './ChatbotProvider'
import LoadingMessage from './LoadingMessage'
import imageCompression from 'browser-image-compression'
import { MdMic, MdMicNone } from 'react-icons/md'

const Chatbot = ({
  initialMessages = [],
  placeholder = 'Type your message',
  validator = (message: string) => message.length > 0,
  parse = (message: string) => undefined,
  disableScrollToBottom = false,
}: IChatbotProps) => {
  const { chatMessages, addUserMessage, isLoading, isRecording, setIsRecording } = useChatbot()
  const [input, setInputValue] = useState('')
  const [isUploading, setIsUploading] = useState('')
  const messageContainerRef = useRef<HTMLDivElement>(null)
  const textInputRef = useRef<HTMLInputElement>(null)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const formRef = useRef<HTMLFormElement>(null)

  const handleUploadClick = () => fileInputRef.current && fileInputRef.current.click()

  const handleImageSelect = async (event: any) => {
    let file = event.target.files[0]
    if (file) {
      try {
        if (file.size / 1024 > 700) {
          // Compress only if size is greater than 700kb
          console.debug(`originalFile size ${file.size / 1024} KB`)
          setIsUploading(file.name)
          const t = performance.now()
          file = await imageCompression(file, { maxSizeMB: 0.7, maxWidthOrHeight: 1920, useWebWorker: true })
          setIsUploading('')
          console.debug(`compressedFile size ${file.size / 1024} KB, in time (s): ${((performance.now() - t) / 1000).toFixed(2)}`)
        }
        const reader = new FileReader()
        reader.onload = () => {
          const base64image = String(reader.result)
          addUserMessage(base64image)
          parse(base64image)
        }
        reader.readAsDataURL(file)
      } catch (error) {
        console.error('Error in uploading image', error)
      }
    }
  }

  const scrollIntoView = () => {
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop = messageContainerRef?.current?.scrollHeight
    }
  }

  const renderMessages = () => {
    return chatMessages.map((messageObject: IMessage) => {
      if (isBotMessage(messageObject)) return <BotChatMessage message={messageObject.message} key={messageObject.id} />
      else if (isUserMessage(messageObject)) return <UserChatMessage message={messageObject.message} key={messageObject.id} />
    })
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    let tempInput = input
    if (!tempInput) {
      const inputElement: HTMLInputElement | null = document.querySelector('.react-chatbot-kit-chat-input')
      tempInput = inputElement?.value ?? ''
    }
    if (validator && typeof validator === 'function' && validator(tempInput)) {
      addUserMessage(tempInput)
      parse(tempInput)
      setInputValue('')
    }
  }

  useEffect(() => {
    if (disableScrollToBottom) return
    scrollIntoView()
  }, [chatMessages, isLoading, isUploading])

  useEffect(() => {
    textInputRef.current && textInputRef.current.focus()
  }, [input, isLoading, isUploading])

  return (
    <div className="react-chatbot-kit-chat-container">
      <div className="react-chatbot-kit-chat-inner-container">
        <div className="react-chatbot-kit-chat-message-container" ref={messageContainerRef}>
          {renderMessages()}
          {isUploading && <UserChatMessage message={`Uploading ${isUploading}`} />}
          {isLoading && <LoadingMessage />}
        </div>
        <div className="react-chatbot-kit-chat-input-container">
          <form ref={formRef} className="react-chatbot-kit-chat-input-form" onSubmit={handleSubmit}>
            <input
              className="react-chatbot-kit-chat-input"
              placeholder={placeholder}
              disabled={isLoading}
              value={input}
              onChange={(e) => {
                console.log(e.target.value)
                setInputValue(e.target.value)
              }}
              ref={textInputRef}
            />
            <button className="react-chatbot-kit-chat-btn-upload" onClick={handleUploadClick} type="button" disabled={isLoading}>
              <MdOutlineFileUpload size={24} className="react-chatbot-kit-chat-btn-upload-icon" />
            </button>
            <input
              type="file"
              accept="image/*"
              onChange={handleImageSelect}
              ref={fileInputRef}
              className="react-chatbot-kit-image-input"
              style={{ display: 'none' }}
            />
            <button className="react-chatbot-kit-chat-btn-send" onClick={handleSubmit} type="button" disabled={isLoading}>
              <MdOutlineSend size={24} className="react-chatbot-kit-chat-btn-send-icon" />
            </button>
            {isRecording ? (
              <MdMic className="absolute -left-8 w-6 h-6 text-gray-600" onClick={() => setIsRecording(false)} />
            ) : (
              <MdMicNone className="absolute -left-8 w-6 h-6 text-gray-400" onClick={() => setIsRecording(true)} />
            )}
          </form>
        </div>
      </div>
    </div>
  )
}

export default Chatbot
