import React, { useContext } from 'react'
import { Mic } from './mic'
import { AvatarType } from '../types'
import { HeygenAvatar, HeygenAvatarProps } from './HeygenAvatar'

export interface AvatarData {
  heygen?: HeygenAvatarProps
}

const AvatarFactory = (avatarType: AvatarType, avatarData: AvatarData) => {
  switch (avatarType) {
    case AvatarType.HEYGEN:
      return <HeygenAvatar {...avatarData.heygen!} />
  }
}

export interface AvatarProps {
  avatarType: AvatarType
  avatarData: AvatarData
}

export const Avatar = ({ avatarType, avatarData }: AvatarProps) => {
  return (
    <>
      <div onClick={() => null} className="relative h-full w-full rounded-full">
        {AvatarFactory(avatarType, avatarData)}
      </div>
      <Mic />
    </>
  )
}
