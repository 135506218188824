import React from 'react'
import BusinessFlows from '../components/slide-screens/BusinessFlows'
import PlansView from '../components/slide-screens/PlansView'
import ScrollingCode from '../components/slide-screens/ScrollingCode'
import Banner from '../components/slide-screens/Banner'
import Integrations from '../components/slide-screens/Integrations'
import Loader from '../components/slide-screens/Loader'
import ConfigureCreds from '../components/slide-screens/ConfigureCreds'
import Actions from '../components/slide-screens/Actions'
import ListItems from '../components/slide-screens/ListItems'
import BirdEyeView from '../components/slide-screens/BirdEyeView'
import { PropsContext } from '../store/GlobalContext'
import ScrollingSchema from '../components/slide-screens/ScrollingSchema'

interface SlidesContentProps {
  layout: string | undefined
  content: any
  heading?: string
}

const SlidesContent = ({ layout, content, heading }: SlidesContentProps) => {
  switch (layout) {
    case 'import-data-modal':
      return <ScrollingSchema content={content} />
    case 'zendesk-configure':
      return <ConfigureCreds />
    case 'loading-sprite':
      return <Loader content={content} />
    case 'flows':
      return <BusinessFlows content={content} />
    case 'plansTable':
      return <PlansView content={content} />
    case 'integrations':
      return <Integrations content={content} heading={heading} />
    case 'grid':
      return <Actions content={content} heading={heading} />
    case 'list':
      return <ListItems content={content} />
    case 'banner':
      return <Banner content={content} />
    case 'code':
      return <ScrollingCode content={content} />
    case 'bird-eye-view':
      return <BirdEyeView content={content} />
    default:
      return <></>
  }
}

const Slides = ({ screenProps }: { screenProps: PropsContext | undefined }) => {
  return (
    <div
      className={`relative w-full h-full bg-white flex items-center justify-center overflow-hidden ${
        ['code'].includes(screenProps?.layout ?? '') ? '' : 'rounded-3xl'
      }`}
    >
      <div className="central-align w-full h-full">
        <SlidesContent layout={screenProps?.layout} content={screenProps?.content} heading={screenProps?.heading} />
      </div>
    </div>
  )
}

export default Slides
