import { Dropdown } from 'flowbite-react'
import React, { useState } from 'react'
import { DEFAULT_SCRIPT_MODE, DEFAULT_AVATAR, DEFAULT_LOCALE } from '../constants'

const OptionsPage = () => {
  const [scriptMode, setScriptMode] = useState(localStorage.getItem('scriptMode') ?? DEFAULT_SCRIPT_MODE)
  const [avatarName, setAvatarName] = useState(localStorage.getItem('avatarName') ?? DEFAULT_AVATAR)
  const [locale, setLocale] = useState(localStorage.getItem('deepgramLocale') ?? DEFAULT_LOCALE)

  const changeScriptMode = (value: string) => {
    localStorage.setItem('scriptMode', value)
    setScriptMode(value)
  }

  const changeAvatar = (value: string) => {
    if (value == 'Monica') localStorage.setItem('avatarName', '')
    else localStorage.setItem('avatarName', value)
    setAvatarName(value)
  }

  const changeLocale = (value: string) => {
    localStorage.setItem('deepgramLocale', value)
    setLocale(value)
  }

  return (
    <div className="m-4 flex flex-col items-center justify-center space-y-4">
      <div className="flex flex-row space-x-4">
        <div className="text-xl">Script Mode:</div>
        <Dropdown label={scriptMode}>
          <Dropdown.Item onClick={() => changeScriptMode('OPENAI_ASSISTANT')}>OPENAI_ASSISTANT</Dropdown.Item>
          <Dropdown.Item onClick={() => changeScriptMode('MWC_SCRIPT')}>MWC_SCRIPT</Dropdown.Item>
          <Dropdown.Item onClick={() => changeScriptMode('STATIC_BOOTH')}>STATIC_BOOTH</Dropdown.Item>
        </Dropdown>
      </div>

      <div className="flex flex-row space-x-4">
        <div className="text-xl">Heygen Avatar Model:</div>

        <Dropdown label={avatarName}>
          <Dropdown.Item onClick={() => changeAvatar('Tyler-incasualsuit-20220721')}>Tyler</Dropdown.Item>
          <Dropdown.Item onClick={() => changeAvatar('Monica')}>Monica</Dropdown.Item>
          <Dropdown.Item onClick={() => changeAvatar('Kiran_studio_20240103')}>Kiran</Dropdown.Item>
        </Dropdown>
      </div>
      <div className="flex flex-row space-x-4">
        <div className="text-xl">Locale:</div>
        <Dropdown label={locale}>
          <Dropdown.Item onClick={() => changeLocale('en-AU')}>English (Australia)</Dropdown.Item>
          <Dropdown.Item onClick={() => changeLocale('en-GB')}>English (Great Britain)</Dropdown.Item>
          <Dropdown.Item onClick={() => changeLocale('en-IN')}>English (India)</Dropdown.Item>
          <Dropdown.Item onClick={() => changeLocale('en-NZ')}>English (New Zealand)</Dropdown.Item>
          <Dropdown.Item onClick={() => changeLocale('en-US')}>English (United States)</Dropdown.Item>
        </Dropdown>
      </div>
    </div>
  )
}

export default OptionsPage
