import React, { useRef, useState } from 'react'
import CircleSelect from './CircleSelect'
import { Tabs, TabsRef } from 'flowbite-react'

// TODO: Remove
const dummyContent = [
  {
    title: 'Alepo',
    icon: 'https://bss.copilots.k8.devfactory.com/integrations/alepo.png',
    alt: 'alepo',
    description: 'Provider of telecommunications and digital solutions.',
  },
  {
    title: 'Amdocs',
    icon: 'https://bss.copilots.k8.devfactory.com/integrations/amdocs.png',
    alt: 'amdocs',
    description: 'Leading software and services provider for communications, media, and entertainment.',
  },
  {
    title: 'Comviva',
    icon: 'https://bss.copilots.k8.devfactory.com/integrations/comviva.png',
    alt: 'comviva',
    description: 'Global leader in mobility solutions, offering digital VAS solutions.',
  },
  {
    title: 'CSG',
    icon: 'https://bss.copilots.k8.devfactory.com/integrations/csg.png',
    alt: 'csg',
    description: 'Leading provider of revenue management and customer engagement solutions.',
  },
  {
    title: 'Ericsson',
    icon: 'https://bss.copilots.k8.devfactory.com/integrations/ericsson.png',
    alt: 'ericsson',
    description: 'Global leader in delivering ICT solutions, including networks and services.',
  },
  {
    title: 'i2i Systems',
    icon: 'https://bss.copilots.k8.devfactory.com/integrations/i2isystems.png',
    alt: 'i2i-systems',
    description: 'Provider of innovative healthcare information solutions and services.',
  },
  {
    title: 'Netcracker',
    icon: 'https://bss.copilots.k8.devfactory.com/integrations/netcracker.png',
    alt: 'netcracker',
    description: 'Leading provider of digital transformation, virtualization, and cloud solutions.',
  },
  {
    title: 'Optiva',
    icon: 'https://bss.copilots.k8.devfactory.com/integrations/optiva.png',
    alt: 'optiva',
    description: 'Provider of monetization and subscriber management solutions for telecoms.',
  },
  {
    title: 'Oracle',
    icon: 'https://bss.copilots.k8.devfactory.com/integrations/oracle.png',
    alt: 'oracle',
    description: 'We help people see data in new ways, discover insights, unlock endless possibilities.',
  },
  {
    title: 'Orga Systems',
    icon: 'https://bss.copilots.k8.devfactory.com/integrations/orgasystems.png',
    alt: 'orga-systems',
    description: 'Provider of real-time charging and billing solutions for digital and mobile services.',
  },
  {
    title: 'STL',
    icon: 'https://bss.copilots.k8.devfactory.com/integrations/stl.png',
    alt: 'stl',
    description: 'Global leader in end-to-end data network solutions.',
  },
  {
    title: 'Telgoo5',
    icon: 'https://bss.copilots.k8.devfactory.com/integrations/telgoo5.png',
    alt: 'telgoo5',
    description: 'Provider of innovative communication and collaboration solutions.',
  },
]

const ImportDataTab = ({ content }: any) => {
  return (
    <div className="grid grid-cols-3 gap-x-8 gap-y-8 mt-4 mb-6 px-4 overflow-visible h-[72vh]">
      {content?.map((item: any) => {
        return (
          <div className={`relative flex flex-col items-start col-span-1 ${item.selected && 'select-item'}`} key={item.title}>
            <CircleSelect isSelected={item.selected} />
            <img
              src={item.icon}
              alt={item.alt}
              className="xl:!h-32 xl:!w-32 2xl:!h-56 2xl:!w-56 object-fill object-center bg-white !border-solid !rounded-lg !pointer-events-auto hover:bg-gray-200 !border-2 !border-gray-200"
            />
            <div className="text-xl 2xl:text-3xl font-medium text-bg2 mt-2 mb-1">{item.title}</div>
            <div className="text-lg 2xl:text-2xl font-light text-gray-500 text-left">{item.description}</div>
          </div>
        )
      })}
    </div>
  )
}

const IntegrationsTab = ({ content }: any) => {
  return (
    <div className="flex flex-col items-start overflow-auto h-[80vh]">
      {content?.map((item: any, idx: number) => {
        return (
          <div key={idx} className="flex flex-col items-start w-full pr-2">
            <div className="font-medium text-lg py-1 px-4 mt-4 text-gray-500 bg-gray-100 rounded-md w-full text-left">{item.value}</div>
            <div className="grid grid-cols-4 gap-x-10 gap-y-10 mt-4 mb-6 px-4 w-full">
              {item.cards.map((card: any) => (
                <div className={`relative flex flex-col items-start col-span-1 ${card.selected && 'select-item'}`} key={card.title}>
                  <CircleSelect isSelected={card.selected} />
                  <img
                    src={card.icon}
                    alt={card.alt}
                    className="!h-24 !w-24 object-fill object-center bg-white !border-solid !rounded-lg !pointer-events-auto hover:bg-gray-200 !border !border-gray-200"
                  />
                  <div className="text-base font-medium text-bg2 mt-2 mb-1">{card.title}</div>
                  <div className="text-sm font-light text-gray-500 text-left">{card.description}</div>
                </div>
              ))}
            </div>
          </div>
        )
      })}
    </div>
  )
}

const Integrations = ({ content, heading }: any) => {
  const tabsRef = useRef<TabsRef>(null)
  const [activeTab, setActiveTab] = useState(1)

  console.log(activeTab)

  return (
    <div className="flex flex-col items-stretch justify-stretch p-8">
      <Tabs aria-label="Default tabs" style="default" ref={tabsRef} onActiveTabChange={(tab) => setActiveTab(tab)}>
        <Tabs.Item title="Import Data">
          <ImportDataTab content={content['Import Data']} />
        </Tabs.Item>
        <Tabs.Item active title="Third Party Integrations">
          <IntegrationsTab content={content['Third Party Integrations']} />
        </Tabs.Item>
      </Tabs>
    </div>
  )
}

export default Integrations
