import React from 'react'

const getPadding = (padding: string | undefined) => {
  return padding ? `p-[${padding}]` : 'p-0'
}

const Banner = ({ content }: any) => {
  return (
    <div className="flex flex-col items-start justify-center w-full h-full font-semibold rounded-lg">
      {content?.map((item: any, index: number) => {
        return item.type == 'image' ? (
          <img
            src={item.value}
            alt={item.alt ?? ''}
            key={index}
            className={`aspect-auto object-fill !w-full !h-full ${getPadding(item.addPadding)}`}
          />
        ) : (
          <div key={index} className="animate-slide opacity-0 text-3xl text-left" style={{ animationDelay: `${7 + index * 2}s` }}>
            {item.value}
          </div>
        )
      })}
    </div>
  )
}

export default Banner
