import React from 'react'
import { isValidBase64Image } from './utilities'

interface UserChatMessageProps {
  message: string
}

const UserChatMessage = ({ message }: UserChatMessageProps) => {
  return (
    <div className="flex justify-end">
      <div className="bg-accent my-2 inline-block max-w-[80%] rounded-3xl px-3 py-2">
        {isValidBase64Image(message) ? <img src={message} alt="image" className="rounded-3xl" /> : message}
      </div>
    </div>
  )
}

export default UserChatMessage
