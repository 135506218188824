import React from 'react'
import { Button } from 'flowbite-react'
import { Link } from 'react-router-dom'

interface CButtonProps {
  type?: 'primary' | 'secondary' | 'tertiary'
  onClick?: () => void
  classname?: string
  isLoading?: boolean
  children: React.ReactNode
}

const CButton = ({ type = 'primary', onClick = () => null, classname = '', isLoading = false, children }: CButtonProps) => {
  switch (type) {
    case 'primary':
      return (
        <Button
          className={`my-1 rounded-md p-0 font-medium text-white ${classname}`}
          fullSized
          color="primary"
          onClick={onClick}
          size="sm"
          isProcessing={isLoading}
          disabled={isLoading}
        >
          {children}
        </Button>
      )
    case 'secondary':
      return (
        <Button
          className={`my-1 rounded-md p-0 font-medium text-primary ${classname}`}
          onClick={onClick}
          fullSized
          outline
          color="primary"
          size="sm"
        >
          {children}
        </Button>
      )
    case 'tertiary':
      return (
        <Link to={'#'} onClick={onClick} className={`font-medium text-primary ${classname}`}>
          {children}
        </Link>
      )
    default:
      return <>{children}</>
  }
}

export default CButton
