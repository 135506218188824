import React, { useEffect, useState } from 'react'
import MemoizedModal from '../../pages/MemoizedModal'
import { useGlobalContext } from '../../store/GlobalContext'

const dummyContent = [
  {
    name: 'Ericsson',
    logo: 'https://bss.copilots.k8.devfactory.com/integrations/ericsson.png',
    code: `from chalice import Chalice

app = Chalice(app_name='amdocs-crm')

# Identify CRM fields
@app.route('/identify-crm-fields')
def identify_crm_fields():
    # Code to identify CRM fields for Amdocs CRM
    fields = ['client_name', 'client_address', 'client_phone']  # Dummy fields
    # Return identified CRM fields
    return {'crm_fields': fields}  #makepurple

# Map CRM fields to Totogi
@app.route('/map-fields-to-totogi')
def map_fields_to_totogi():
    # Code to map Amdocs CRM fields to Totogi fields
    field_mappings = {
        'client_name': 'Name',
        'client_address': 'Address',
        'client_phone': 'Phone'
    }
    # Return mapped fields
    return {'field_mappings': field_mappings}  #makepurple

# Import data
@app.route('/import-data')
def import_data():
    # Code to import data from Amdocs CRM to Totogi
    # Dummy code for data import
    return {'message': 'Data imported successfully'}  #makepurple
`,
  },
  {
    name: 'Amdocs',
    code: `from chalice import Chalice

app = Chalice(app_name='amdocs-crm')

# Identify CRM fields
@app.route('/identify-crm-fields')
def identify_crm_fields():
    # Code to identify CRM fields for Amdocs CRM
    fields = ['client_name', 'client_address', 'client_phone']  # Dummy fields
    # Return identified CRM fields
    return {'crm_fields': fields}  #makepurple

# Map CRM fields to Totogi
@app.route('/map-fields-to-totogi')
def map_fields_to_totogi():
    # Code to map Amdocs CRM fields to Totogi fields
    field_mappings = {
        'client_name': 'Name',
        'client_address': 'Address',
        'client_phone': 'Phone'
    }
    # Return mapped fields
    return {'field_mappings': field_mappings}  #makepurple

# Import data
@app.route('/import-data')
def import_data():
    # Code to import data from Amdocs CRM to Totogi
    # Dummy code for data import
    return {'message': 'Data imported successfully'}  #makepurple
`,
    logo: 'https://bss.copilots.k8.devfactory.com/integrations/amdocs.png',
  },
  {
    name: 'Huawei',
    code: `from chalice import Chalice

app = Chalice(app_name='huawei-crm')

# Identify CRM fields
@app.route('/identify-crm-fields')
def identify_crm_fields():
    # Code to identify CRM fields for Huawei CRM
    fields = ['company_name', 'company_address', 'company_phone']  # Dummy fields
    # Return identified CRM fields
    return {'crm_fields': fields}  #makepurple

# Map CRM fields to Totogi
@app.route('/map-fields-to-totogi')
def map_fields_to_totogi():
    # Code to map Huawei CRM fields to Totogi fields
    field_mappings = {
        'company_name': 'Name',
        'company_address': 'Address',
        'company_phone': 'Phone'
    }
    # Return mapped fields
    return {'field_mappings': field_mappings}  #makepurple

# Import data
@app.route('/import-data')
def import_data():
    # Code to import data from Huawei CRM to Totogi
    # Dummy code for data import
    return {'message': 'Data imported successfully'}  #makepurple
`,
    logo: 'https://bss.copilots.k8.devfactory.com/integrations/Huawei.png',
    records: [
      {
        name: 'Emily Davis',
        phone: '777-888-9999',
        address: '456 Maple St, Somecity, USA',
      },
      {
        name: 'Michael Brown',
        phone: '000-999-8888',
        address: '987 Cedar St, Othertown, USA',
      },
      {
        name: 'David Wilson',
        phone: '333-222-1111',
        address: '654 Oak St, Anytown, USA',
      },
      {
        name: 'Laura Miller',
        phone: '222-333-4444',
        address: '345 Elm St, Othertown, USA',
      },
      {
        name: 'Ryan Garcia',
        phone: '555-777-9999',
        address: '678 Pine St, Somecity, USA',
      },
      {
        name: 'Olivia Lee',
        phone: '111-222-3333',
        address: '876 Maple St, Anytown, USA',
      },
    ],
  },
]

const ScrollingSchema = ({ content }: any) => {
  const [showCustomerRecords, setShowCustomerRecords] = useState(false)

  const renderCode = (value: any) => {
    const codeLines = value?.replaceAll('bss.copilots.k8.devfactory.com', 'assets.bssmagic.totogi.solutions').split('\n') ?? []
    const processedLines = codeLines.map((line: string, idx: number) => {
      const makePurple = line.endsWith('#makepurple')
      const processedLine = makePurple ? line.replace('#makepurple', '') : line
      return (
        <div className={makePurple ? 'text-purple-400' : ''} key={idx}>
          {processedLine ? processedLine : <br />}
        </div>
      )
    })

    return (
      value && (
        <div className="flex flex-col bg-[#1C1C1C] text-white h-full w-full items-stretch justify-stretch flex-1 my-2 overflow-hidden">
          <pre className={`p-4 text-left text-sm scroll-text-2`}>
            <code>{processedLines}</code>
          </pre>
        </div>
      )
    )
  }

  useEffect(() => {
    setTimeout(() => {
      setShowCustomerRecords(true)
    }, 5000)
  }, [])

  return (
    <div className="flex flex-col items-stretch justify-stretch w-full h-36 xl:h-[13.3rem] 2xl:h-[14.6rem] space-y-4 bg-white p-4 rounded-xl">
      {content?.map((item: any, idx: number) => {
        return (
          <div className="flex flex-row items-center w-full h-full bg-gray-100 rounded-xl border-2 border-gray-400" key={idx}>
            <div className="flex flex-col items-center justify-center w-fit p-6 my-4 space-y-2">
              <img src={item.logo} alt={item.name} className="!h-24 !w-24 object-fill object-center bg-white !rounded-xl" />
              <div className="text-base font-medium text-gray-500">{item.name}</div>
            </div>
            {renderCode(item.code)}
            <div className="flex flex-col items-start justify-start px-4 py-6 w-1/4 h-full overflow-hidden">
              <div className={`flex flex-col items-start justify-start space-y-4 ${showCustomerRecords && 'scroll-text-3'}`}>
                {showCustomerRecords &&
                  (item.records ?? []).map((customer: any, cid: number) => {
                    return (
                      <div className="flex flex-col" key={cid}>
                        <div className="text-sm font-medium">{customer.name}</div>
                        <div className="text-xs font-light">{customer.phone}</div>
                        <div className="text-xs font-light">{customer.address}</div>
                      </div>
                    )
                  })}
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export const MModalTest = () => {
  const { openModal, setOpenModal, modalUrl } = useGlobalContext()
  if (openModal) {
    openModal.type = 'slides'
    openModal.props = {
      layout: 'import-data-modal',
      content: dummyContent,
    }
  }
  return <MemoizedModal modalState={openModal} setModalState={setOpenModal} modalUrl={modalUrl} />
}

export default ScrollingSchema
