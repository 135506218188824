import { createClient } from '@deepgram/sdk'

export class DeepgramService {
  constructor() {
    this.deepgram = createClient(localStorage.getItem('deepgram_key'))
    this.socket = null
    this.microphone = null
    this.transcript = ''
    this.messageTimeoutId = null
    this.addTranscript = undefined
    this.pingInterval = null
  }

  async getMicrophone() {
    const userMedia = await navigator.mediaDevices.getUserMedia({ audio: true })
    return new MediaRecorder(userMedia)
  }

  async openMicrophone() {
    if (!this.microphone) return
    this.microphone.start(500)
    this.microphone.onstart = () => console.log('client: microphone opened')
    this.microphone.onstop = () => console.log('client: microphone closed')
    this.microphone.onpause = () => console.log('client: microphone paused')
    this.microphone.onresume = () => console.log('client: microphone resumed')
    this.microphone.onerror = (e) => console.log('client: microphone error', e)
    this.microphone.ondataavailable = (e) => this.socket.send(e.data)
  }

  closeMicrophone() {
    if (this.microphone) this.microphone.stop()
  }

  async toggleMicrophone() {
    if (!this.microphone) return
    if (this.microphone.state === 'recording') this.microphone.pause()
    else if (this.microphone.state === 'paused') this.microphone.resume()
  }

  async setupMicrophone() {
    if (!this.microphone) {
      this.microphone = await this.getMicrophone()
      await this.openMicrophone()
    }
  }

  setup() {
    if (this.socket) return

    this.socket = this.deepgram.listen.live({
      model: 'nova-2-general',
      smart_format: true,
      interim_results: true,
      utterance_end_ms: 2000,
      replace: [`mister beast:MrBeast`, 'pillars:colors', '3 60:360'],
      keywords: ['Totogi', 'BSS', 'MVNO'],
      language: localStorage.getItem('deepgramLocale') ?? 'en-IN',
    })

    this.socket.on('open', async () => {
      console.log('deepgram client: connected to websocket')
      await this.setupMicrophone()

      // sendKeepAlive message every 8s
      this.pingInterval = setInterval(() => {
        this.socket.send(JSON.stringify({ type: 'KeepAlive' }))
        console.debug('Sent Deepgram ping')
      }, 8000)
    })

    this.socket.on('UtteranceEnd', (data) => {
      this.addTranscript(this.transcript.trim())
      this.transcript = ''
    })

    this.socket.on('Results', (data) => {
      const interimTranscript = data.channel.alternatives[0].transcript.trim()
      const isSpeechComplete = data.is_final
      if (interimTranscript == '' || !isSpeechComplete) return
      this.transcript += (this.transcript.length ? ' ' : '') + interimTranscript
    })

    this.socket.on('error', (e) => console.error(e))

    this.socket.on('warning', (e) => console.warn(e))

    this.socket.on('Metadata', (e) => console.log(e))

    this.socket.on('close', (e) => console.log(e))
  }

  cleanup() {
    clearInterval(this.pingInterval)
    this.socket.finish()
    this.closeMicrophone()
  }
}
