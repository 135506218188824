import type { CustomFlowbiteTheme } from 'flowbite-react'

const customTheme: CustomFlowbiteTheme = {
  accordion: {
    content: {
      base: 'p-0 m-0 dark:bg-gray-900',
    },
  },
  button: {
    color: {
      primary:
        'text-white bg-primary border border-transparent enabled:hover:bg-primary focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:enabled:hover:bg-primary dark:focus:ring-primary',
      secondary:
        'text-white bg-secondary border border-transparent enabled:hover:bg-secondary focus:ring-4 focus:ring-secondary-300 dark:bg-secondary-600 dark:enabled:hover:bg-secondary dark:focus:ring-secondary',
      zendesk: 'text-[#013D45] bg-white border border-transparent enabled:hover:bg-white focus:ring-4 focus:ring-white',
    },
    outline: {
      color: {
        gray: 'border border-gray-900 dark:border-white',
        primary: 'border border-primary dark:border-white',
        default: 'border',
        light: '',
      },
      off: '',
      on: 'flex justify-center bg-white font-bold text-primary transition-all duration-75 ease-in group-enabled:group-hover:bg-opacity-0 group-enabled:group-hover:text-white dark:bg-gray-900 dark:text-white w-full',
    },
  },
  progress: {
    color: {
      primary: 'bg-primary',
    },
  },
  modal: {
    content: {
      base: 'relative h-full w-full md:h-auto outline-none ring-0 focus:outline-none focus:ring-0',
      inner: 'relative rounded-3xl bg-white shadow flex flex-col max-h-[90vh]',
    },
    root: {
      sizes: {
        custom: 'sm:max-w-xl md:max-w-2xl lg:max-w-3xl xl:max-w-6xl 2xl:max-w-7xl',
        '8xl': 'max-w-[88rem]',
        '9xl': 'max-w-[96rem]',
        '10xl': 'max-w-[104rem]',
      },
    },
  },
  table: {
    head: {
      base: 'group/head text-xs uppercase text-gray-700 dark:text-gray-400',
      cell: {
        base: 'bg-gray-50 dark:bg-gray-700 px-3 py-3 w-fit',
      },
    },
    body: {
      base: 'group/body',
      cell: {
        base: 'px-3 py-3',
      },
    },
  },
  tabs: {
    tablist: {
      tabitem: {
        base: 'flex items-center justify-center p-4 rounded-t-lg text-lg font-medium first:ml-0 disabled:cursor-not-allowed disabled:text-gray-400 disabled:dark:text-gray-500 focus:outline-none',
        styles: {
          default: {
            active: {
              on: 'bg-gray-100 text-primary',
              off: 'text-gray-500 hover:bg-gray-50 hover:text-gray-600',
            },
          },
        },
      },
    },
  },
}

export default customTheme
