import React, { useState } from 'react'
import { Button, Label, TextInput } from 'flowbite-react'
import SlideHeading from './SlideHeading'
import { LuEye } from 'react-icons/lu'
import { LuEyeOff } from 'react-icons/lu'
import { useGlobalContext } from '../../store/GlobalContext'

const ConfigureCreds = () => {
  const { handleUserMessage } = useGlobalContext()
  const [isDisabled, setIsDisabled] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  return (
    <div className="flex flex-col items-center justify-center h-full w-full p-8 py-24">
      <SlideHeading heading="Configure Zendesk" className="h-1/6" />
      <div className="flex flex-col flex-1 w-[50%] items-center justify-start space-y-14 rounded-2xl bg-[#013D45] shadow-sm p-8">
        <img
          src="https://bss.copilots.k8.devfactory.com/zendesk-full.png"
          alt="zendesk-full-logo"
          className="!w-32 !h-32 object-contain aspect-auto"
        />
        <div className="flex flex-col items-stretch w-full">
          <Label htmlFor="Username" value="Username" className="text-white text-left text-2xl" />
          <input id="Username" type="text" className="mt-2 p-2 rounded-md w-full bg-opacity-10 bg-white text-white h-16" />
        </div>
        <div className="flex flex-col items-stretch w-full relative">
          <Label htmlFor="API Token" value="API Token" className="text-white text-left text-2xl" />
          <input
            id="API Token"
            type={showPassword ? 'text' : 'password'}
            className="mt-2 p-2 rounded-md w-full bg-opacity-10 bg-white text-white h-16"
          />
          <div className="absolute right-0 bottom-0 flex items-center pr-3 pb-3 text-white">
            {showPassword ? (
              <LuEye onClick={() => setShowPassword(false)} size={'2rem'} />
            ) : (
              <LuEyeOff onClick={() => setShowPassword(true)} size={'2rem'} />
            )}
          </div>
        </div>
        <Button
          fullSized
          color="zendesk"
          type="submit"
          onClick={() => {
            setIsDisabled(true)
            handleUserMessage && handleUserMessage('y')
          }}
          disabled={isDisabled}
          processingSpinner={isDisabled}
        >
          <span className="text-2xl">SUBMIT </span>
        </Button>
      </div>
      <div className="h-1/6"></div>
    </div>
  )
}

export default ConfigureCreds
