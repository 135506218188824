export enum AvatarType {
  GLB,
  EX_HUMAN,
  IMAGE,
  HEYGEN,
}

export enum MicState {
  ON,
  OFF,
  DISABLED_ON,
  DISABLED_OFF,
}

export enum AudioState {
  ON,
  OFF,
}
