import { createContext } from 'react'
import { AudioState, MicState } from '.'

interface BaseChatBotContextProps {
  // mic states
  micState?: MicState
  setMicState?: (micState: MicState) => void

  // audio states
  audioEnabled?: boolean
}

export const BaseChatBotContext = createContext({} as BaseChatBotContextProps)
