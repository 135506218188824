import React, { useContext, useEffect, useRef } from 'react'
import HeygenService from '../../../services/HeygenService'
import { useGlobalContext } from '../../../store/GlobalContext'

export interface HeygenAvatarProps {
  heygenService: HeygenService
}

export const HeygenAvatar = ({ heygenService }: HeygenAvatarProps) => {
  const avatarRef = useRef<HTMLVideoElement | null>(null)
  const canvasRef = useRef<HTMLCanvasElement | null>(null)
  const { screenType } = useGlobalContext()

  useEffect(() => {
    function waitForImage(imgElem: HTMLImageElement): Promise<void> {
      return new Promise((res) => {
        if (imgElem.complete) {
          return res()
        }
        imgElem.onload = () => res()
        imgElem.onerror = () => res()
      })
    }

    const playVideo = (event: any) => {
      if (event.ctrlKey && event.code === 'Space') {
        event.preventDefault()
        avatarRef.current?.play()
      }
    }

    const closeSession = function (event: any) {
      if (event.keyCode != 192) {
        return
      }
      if (avatarRef.current) {
        avatarRef.current.pause()
      }
      heygenService.closeHeygenSession()
    }

    const loadImage = async function () {
      const backgroundImage = new Image()
      backgroundImage.src = 'avatar_background.jpg'
      await waitForImage(backgroundImage)
      return backgroundImage
    }

    const setup = async () => {
      heygenService.avatarRef = avatarRef
      await heygenService.setupHeygenSession()
      document.addEventListener('keydown', closeSession, false)
      document.addEventListener('keyup', playVideo, false)
    }

    const setupWidths = function (canvas: HTMLCanvasElement, videoCanvas: HTMLCanvasElement) {
      const canvasSize = canvas.getBoundingClientRect()
      canvas.width = canvasSize.width
      canvas.height = canvasSize.height
      const minCoordinate = Math.min(canvasSize.width, canvasSize.height)
      videoCanvas.height = minCoordinate
      if (canvas.height < 0.7 * window.innerHeight) videoCanvas.width = minCoordinate * 2
      else videoCanvas.width = minCoordinate
      // console.log(canvasSize)
    }

    const setupCanvas = async (avatarVideo: HTMLVideoElement, canvas: HTMLCanvasElement) => {
      avatarVideo.addEventListener('play', async () => {
        const img: HTMLImageElement = await loadImage()
        const videoCanvas = document.createElement('canvas')

        function calculateShift(videoWidth: number, canvasWidth: number, videoHeight: number, canvasHeight: number) {
          // Calculate the number of rows and columns to add on each side to center the video
          const horizontalShift = Math.floor((canvasWidth - videoWidth) / 2 + 0.2 * videoWidth)
          const verticalShift = Math.floor((canvasHeight - videoHeight) / 2)
          return { horizontalShift, verticalShift }
        }

        let index = 0
        const canvas = canvasRef.current
        let canvasContext: CanvasRenderingContext2D | null, videoCanvasContext: CanvasRenderingContext2D | null
        if (canvas) canvasContext = canvas.getContext('2d', { willReadFrequently: true })
        if (videoCanvas) videoCanvasContext = videoCanvas.getContext('2d', { willReadFrequently: true })

        function segmentPeople() {
          const isVideoPlaying = (video: HTMLVideoElement): boolean =>
            !!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2)
          if (avatarRef.current && !isVideoPlaying(avatarRef.current)) {
            return
          }
          const date: Date = new Date()

          index += 1
          if (canvas) {
            if (index % 2 == 1) {
              setupWidths(canvas, videoCanvas)
            }

            if (canvasContext && videoCanvasContext && avatarRef.current) {
              const videoWidth = avatarRef.current.videoWidth
              const videoHeight = avatarRef.current.videoHeight
              if (canvas.height < 0.7 * window.innerHeight)
                videoCanvasContext.drawImage(
                  avatarRef.current,
                  0,
                  70,
                  videoWidth,
                  videoHeight / 2 + 70,
                  0,
                  0,
                  videoCanvas.width,
                  videoCanvas.height
                )
              else
                videoCanvasContext.drawImage(avatarRef.current, 0, 0, videoWidth, videoHeight, 0, 0, videoCanvas.width, videoCanvas.height)
              const videoFrame = videoCanvasContext.getImageData(0, 0, videoCanvas.width, videoCanvas.height)
              if (index % 2 == 1) {
                canvasContext.drawImage(img, 0, 0, canvas.width, canvas.height)
              }
              const videoPixels = videoFrame.data
              const canvasFrame = canvasContext.getImageData(0, 0, canvas.width, canvas.height)
              const canvasPixels = canvasFrame.data
              let red, green, blue
              const { horizontalShift, verticalShift } = calculateShift(videoCanvas.width, canvas.width, videoCanvas.height, canvas.height)

              let videoHeightIdx: number, videoRow: number, videoIndex: number, canvasIndex: number
              const maxVideoHeight = videoCanvas.height
              const maxVideoWidth = videoCanvas.width
              const maxCanvasWidth = canvas.width
              const videoStartIndex: number = Math.floor(0.2 * maxVideoWidth)
              const videoEndIndex: number = Math.floor(0.8 * maxVideoWidth)
              for (videoHeightIdx = 0; videoHeightIdx < maxVideoHeight; videoHeightIdx++) {
                videoIndex = (videoHeightIdx * maxVideoWidth + videoStartIndex) * 4
                canvasIndex = ((videoHeightIdx + verticalShift) * maxCanvasWidth + horizontalShift) * 4
                for (videoRow = videoStartIndex; videoRow < videoEndIndex; videoRow++) {
                  // canvasIndex += videoRow
                  red = videoPixels[videoIndex]
                  green = videoPixels[videoIndex + 1]
                  blue = videoPixels[videoIndex + 2]
                  if (
                    (red < 30 && blue < 30 && green > 70) ||
                    (green > 100 && blue < 80 && red < 80) ||
                    (green > 120 && blue < 100 && red < 100) ||
                    (green > 150 && blue < 120 && red < 120) ||
                    (green > 160 && blue < 125 && red < 125)
                  ) {
                    // Intentionally left empty to skip processing for green-dominant pixels
                  } else {
                    canvasPixels[canvasIndex] = videoPixels[videoIndex]
                    canvasPixels[canvasIndex + 1] = videoPixels[videoIndex + 1]
                    canvasPixels[canvasIndex + 2] = videoPixels[videoIndex + 2]
                  }
                  canvasIndex += 4
                  videoIndex += 4

                  // canvasPixels[canvasIndex + 3] = videoPixels[videoIndex + 3]; // A
                }
              }

              // if (index % 20 == 1) {
              //   console.debug('fps5', 1000 / (new Date().getTime() - date.getTime()))
              // }
              canvasContext.putImageData(canvasFrame, 0, 0)
              // document.getElementById("fps").innerHTML = 1000 / (new Date().getTime() - date.getTime() + 5);
            }
          }
          setTimeout(segmentPeople, 10)
        }
        segmentPeople()
      })
    }

    const avatarVideo = avatarRef.current
    const canvas = canvasRef.current
    if (avatarVideo && canvas) {
      setup()
      setupCanvas(avatarVideo, canvas)
    }

    return () => {
      document.removeEventListener('keydown', closeSession, false)
      document.removeEventListener('keyup', playVideo, false)
      heygenService.closeHeygenSession()
    }
  }, [])

  return (
    <div className="relative h-full w-full">
      <canvas ref={canvasRef} className="h-full w-full"></canvas>
      <video ref={avatarRef} autoPlay className="hidden" />
      {/* <p>FPS:<span id="fps">  </span></p> */}
      <img
        src="/assets/logo.svg"
        alt="totogi-logo"
        className={`absolute bottom-0 right-0 intro-transition ${
          screenType && screenType != 'full-screen-avatar' ? 'w-16 mx-4 my-4' : 'w-28 m-4'
        }`}
      />
    </div>
  )
}
