import React from 'react'
import { MdOutlineEdit, MdOutlineGroup, MdOutlineFaceUnlock, MdOutlineSettings, MdOutlineInventory, MdChecklistRtl } from 'react-icons/md'

const colorsMap = [
  {
    background: 'bg-violet-100',
    border: 'border-purple-700',
  },
  {
    background: 'bg-blue-200',
    border: 'border-blue-600',
  },
  {
    background: 'bg-orange-50',
    border: 'border-orange-500',
  },
  {
    background: 'bg-green-50',
    border: 'border-green-600',
  },
  {
    background: 'bg-indigo-50',
    border: 'border-indigo-700',
  },
  {
    background: 'bg-gray-200',
    border: 'border-stone-700',
  },
]

const iconMap = {
  'Design Products': <MdOutlineEdit></MdOutlineEdit>,
  CRM: <MdOutlineGroup></MdOutlineGroup>,
  'Self Care': <MdOutlineFaceUnlock></MdOutlineFaceUnlock>,
  'Order Management': <MdOutlineSettings></MdOutlineSettings>,
  'Inventory Management': <MdOutlineInventory></MdOutlineInventory>,
  'Case Management': <MdChecklistRtl></MdChecklistRtl>,
}

const TitleIcon = ({ title }: any) => {
  switch (title) {
    case 'Design Products':
      return <MdOutlineEdit></MdOutlineEdit>
    case 'CRM':
      return <MdOutlineGroup></MdOutlineGroup>
    case 'Self Care':
      return <MdOutlineFaceUnlock></MdOutlineFaceUnlock>
    case 'Order Management':
      return <MdOutlineSettings></MdOutlineSettings>
    case 'Inventory Management':
      return <MdOutlineInventory></MdOutlineInventory>
    case 'Case Management':
      return <MdChecklistRtl></MdChecklistRtl>
    default:
      return <></>
  }
}

const BirdEyeElement = ({ item, idx }: any) => {
  const colored_class = `${colorsMap[idx]['background']} ${colorsMap[idx]['border']} `
  return (
    <div className="w-full self-stretch flex flex-col">
      <div className="w-full px-4 py-2 bg-neutral-100 rounded-lg justify-start items-center gap-2 flex flex-row text-gray-600">
        <TitleIcon title={item.title} />
        <div className="font-medium flex justify-center items-center">{item.title}</div>
      </div>
      <div className="self-stretch pl-10 mt-3 justify-start items-center flex flex-row flex-wrap">
        {item.elements?.map((element: any, elemIdx: number) => {
          return (
            <div key={elemIdx} className="flex flex-row justify-start items-center gap-0.5 mb-4 ">
              {element.selected ? (
                <div className="add-backdrop">
                  {' '}
                  <div className="border-4 border-white">
                    <div className="px-4 p-2  rounded border-t-[3px]  justify-center items-center gap-1 flex !bg-violet-100 !border-purple-700">
                      <div className="text-black text-opacity-90 text-base font-normal justify-center items-center text-center !bg-violet-100">
                        {element.title}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className={`px-4 p-2  rounded border-t-[3px]  justify-center items-center gap-1 flex ${colored_class}`}>
                  <div className="text-black text-opacity-90 text-base font-normal justify-center items-center text-center">
                    {element.title}
                  </div>
                </div>
              )}

              {elemIdx != item.elements.length - 1 ? (
                <svg className="mx-1" width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M23.0607 12.5607C23.6464 11.9749 23.6464 11.0251 23.0607 10.4393L13.5147 0.893398C12.9289 0.307611 11.9792 0.307611 11.3934 0.893398C10.8076 1.47919 10.8076 2.42893 11.3934 3.01472L19.8787 11.5L11.3934 19.9853C10.8076 20.5711 10.8076 21.5208 11.3934 22.1066C11.9792 22.6924 12.9289 22.6924 13.5147 22.1066L23.0607 12.5607ZM0 13H22V10H0V13Z"
                    fill="black"
                    fill-opacity="0.12"
                  />
                </svg>
              ) : (
                ''
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}

const BirdEyeView = ({ content }: any) => {
  console.log(content)
  return (
    <div className="flex flex-col items-start justify-start py-4 overflow-y-scroll px-8 w-full h-full font-semibold rounded-lg">
      {content?.map((item: any, idx: number) => {
        return <BirdEyeElement key={idx} item={item} idx={idx}></BirdEyeElement>
      })}
    </div>
  )
}

export default BirdEyeView
