import { TextInput } from 'flowbite-react'
import React from 'react'
import CButton from '../components/base-components/CButton'
import { useNavigate } from 'react-router-dom'
import { useImmer } from 'use-immer'

const ConfigCard = ({ title, children }: any) => {
  return (
    <div className="flex flex-col px-8 py-4 space-y-2 w-5/6">
      <div className="font-medium text-2xl italic">{title}</div>
      <div className="flex flex-col space-y-2 w-full ml-4">{children}</div>
      <hr />
    </div>
  )
}

const Configuration = () => {
  const navigate = useNavigate()
  const [config, setConfig] = useImmer({
    github: { repo: 'https://github.com/totogi/bss-app' },
    aws: {
      account_id: '324613809385',
      access_key: 'ASIAUXFD5UTU5742427O',
      secret_access_key: 'eqSBIpMQtJ23yAWlyf8qvzhTrsh5RYxyXP8x6L4A',
      session_token:
        'FwoGZXIvYXdzEGwaDA63bPcz6Sh8UyQ/fSLvAZk5qpDDB080F1ciiMwOhwiWCInJutd9XS1qKI7NEpbS7oXgtuVRIb21yR3jWM3BO0EJ+nfjsDFejv1nadYCUKsMpwVM6d3UrUt/Ff+VWkLw9/2m1l5hSlYdU8YB2AZ7a67MiZbKW6ikn+0jW1FeokYpjzxf2MvlaebGPc8MxK3SkFd94coKIFICME71lkFYhUzt7tJReL96MUqHaFA+3r2Ym7Y73w2QjhbfhgdmKgw1gvVKVTBbMRrk0SsRSSFzeTwjnTPtX8FU5wTMQDG73g8LpV8QqUypAyNspH5+eFkC7VnFvmmjSI5F2TaBZlJ2KPSt164GMjKkJ8Q4j+LgLDkdBoq1w9HaWLWGu6gUg6z/s9PudTs+T6hyTp1s/Lb1Q0PvXF2RPhI+7g==',
    },
    totogi: {
      username: 'mwc2024',
      password: 'R1wlNb19hEW7',
    },
  })

  return (
    <div className="p-4 flex flex-col items-start justify-center space-y-4 w-full overflow-auto">
      <div className="flex font-bold text-5xl mx-auto">BSS Magic Configuration</div>
      <ConfigCard title="Github configuration">
        <TextInput
          id="repo"
          type="text"
          placeholder="Github Repo"
          required
          helperText="Enter the repository url where you want the code to be generated in"
          addon={<div className="inline-block">Repository</div>}
          value={config.github.repo}
          onChange={(e) =>
            setConfig((draft) => {
              draft.github.repo = e.target.value
            })
          }
        />
      </ConfigCard>
      <ConfigCard title="AWS configuration">
        <TextInput
          id="AWS_ACCOUNT_ID"
          type="password"
          placeholder="AWS_ACCOUNT_ID"
          required
          value={config.aws.account_id}
          addon={<div className="inline-block">AWS_ACCOUNT_ID</div>}
          onChange={(e) =>
            setConfig((draft) => {
              draft.aws.account_id = e.target.value
            })
          }
        />
        <TextInput
          id="AWS_ACCESS_KEY_ID"
          type="password"
          placeholder="AWS_ACCESS_KEY_ID"
          required
          value={config.aws.access_key}
          addon={<div className="inline-block">AWS_ACCESS_KEY_ID</div>}
          onChange={(e) =>
            setConfig((draft) => {
              draft.aws.access_key = e.target.value
            })
          }
        />
        <TextInput
          id="AWS_SECRET_ACCESS_KEY"
          type="password"
          placeholder="AWS_SECRET_ACCESS_KEY"
          required
          value={config.aws.secret_access_key}
          addon={<div className="inline-block">AWS_SECRET_ACCESS_KEY</div>}
          onChange={(e) =>
            setConfig((draft) => {
              draft.aws.secret_access_key = e.target.value
            })
          }
        />
        <TextInput
          id="AWS_SESSION_TOKEN"
          type="password"
          placeholder="AWS_SESSION_TOKEN"
          value={config.aws.session_token}
          addon={<div className="inline-block">AWS_SESSION_TOKEN</div>}
          onChange={(e) =>
            setConfig((draft) => {
              draft.aws.session_token = e.target.value
            })
          }
        />
      </ConfigCard>
      <ConfigCard title="Totogi BSS configuration">
        <TextInput
          id="Username"
          type="text"
          placeholder="Username"
          required
          value={config.totogi.username}
          addon={<div className="block">Username</div>}
          onChange={(e) =>
            setConfig((draft) => {
              draft.totogi.username = e.target.value
            })
          }
        />
        <TextInput
          id="Password"
          type="password"
          placeholder="Password"
          required
          value={config.totogi.password}
          addon={<div className="block">Password</div>}
          onChange={(e) =>
            setConfig((draft) => {
              draft.totogi.password = e.target.value
            })
          }
        />
      </ConfigCard>
      <CButton classname="w-1/6 mx-auto font-medium" onClick={() => navigate('/login')}>
        SAVE
      </CButton>
    </div>
  )
}

export default Configuration
