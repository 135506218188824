import React from 'react'
import Markdown from 'markdown-to-jsx'

const CustomMarkdown = ({ children }: any) => {
  return (
    <Markdown
      options={{
        wrapper: React.Fragment,
        namedCodesToUnicode: {
          '\n': '\u000A',
          '&nbsp': '\u000A',
        },
        overrides: {
          a: {
            component: ({ children, ...props }: any) => (
              <a {...props} target="_blank" rel="noopener noreferrer">
                {children}
              </a>
            ),
            props: { className: 'underline text-blue-600 hover:text-blue-800 visited:text-purple-600 break-words' },
          },
          h3: {
            component: ({ children, ...props }: any) => <div {...props}>{children}</div>,
            props: { className: 'text-lg font-bold' },
          },
          strong: {
            component: ({ children, ...props }: any) => <div {...props}>{children}</div>,
            props: { className: 'font-bold inline-block mt-4' },
          },
        },
      }}
    >
      {children}
    </Markdown>
  )
}

export const TestMarkdown = () => {
  const message =
    '### Beast mode \n\nThe Beast Mode plan offers unlimited voice, text, and data for just $49 a month.\n\n**What sets this plan apart** is its commitment to the environment. For every gigabyte of data used on this plan, MrBeast, [https://bssdemo.bssmagic.totogi.solutions](<bss-app-url>) in collaboration with the Telco company, will plant a tree. By choosing the Beast Mode plan, not only are you getting unlimited connectivity, but you are also actively contributing to a greener future. \n\n Line1\nLine2\nLine3'
  return <CustomMarkdown>{message}</CustomMarkdown>
}

export default CustomMarkdown
