import React from 'react'

const Loader = ({ content }: any) => {
  return (
    <div className="bg-white h-full w-full rounded-xl overflow-hidden flex flex-col items-center justify-center space-y-4">
      {/* <div className="h-1/5 w-full"></div> */}
      {/* <div className="h-full w-full !m-0 flex flex-1 rounded-xl overflow-hidden bg-loader object-fill bg-center bg-no-repeat bg-contain"/> */}
      <video className="w-full h-full !m-0 flex flex-1 overflow-hidden" muted autoPlay loop src="/spinner-white.mov" />
      {/* <div className="h-1/5 w-full font-semibold text-gray-500 text-3xl">{content}</div> */}
    </div>
  )
}

export default Loader
