import React, { useCallback, useEffect } from 'react'
import { isLink } from './utilities'
import { useGlobalContext } from '../../../store/GlobalContext'
import CustomMarkdown from './CustomMarkdown'

interface BotChatMessageProps {
  message: string
  loader?: any
}

const BotChatMessage = ({ message }: BotChatMessageProps) => {
  const { appUrl } = useGlobalContext()

  const renderMessageWithLinks = useCallback(() => {
    const appUrlOrigin = new URL(appUrl ?? '').origin
    return message.split(' ').map((word: string, index: number) => {
      if (isLink(word))
        return (
          <span>
            <a
              key={index}
              href={appUrlOrigin}
              target="_blank"
              rel="noreferrer"
              className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600 break-words"
            >
              {appUrlOrigin.replace(/https:\/\/[^.]+/, 'https://bssdemo')}{' '}
            </a>
          </span>
        )
      else return <span key={index}>{word} </span>
    })
  }, [])

  const renderMarkdown = () => {
    const appUrlOrigin = new URL(appUrl ?? '').origin
    const newMessage = message.replaceAll('<bss-app-url>', appUrlOrigin)
    return <CustomMarkdown>{newMessage}</CustomMarkdown>
  }

  return (
    <div className="flex justify-start">
      <div className="my-2 inline-block max-w-[80%] rounded-3xl bg-white px-3 py-2">{renderMarkdown()}</div>
    </div>
  )
}

export default BotChatMessage
