import React, { useEffect, useState } from 'react'
import { BaseChatBotContext } from './BaseChatBotContext'
import { Avatar, AvatarData } from './avatar'
import { AvatarType, MicState } from './types'
import { Accordion } from 'flowbite-react'
import { FiChevronUp, FiChevronDown } from 'react-icons/fi'
import Chatbot from './Chatbot'
import { useGlobalContext } from '../../store/GlobalContext'

export interface BaseChatBotProps {
  // avatar sttes
  avatarType?: AvatarType
  avatarData?: AvatarData

  // audio states
  audioEnabled?: boolean

  // mic states
  micState?: MicState
  setMicState?: (micState: MicState) => void
}

export const BaseChatBot = ({
  avatarType = AvatarType.HEYGEN,
  avatarData = { heygen: undefined },
  micState = MicState.DISABLED_OFF,
  setMicState,
  audioEnabled,
}: BaseChatBotProps) => {
  const { screenType, handleUserMessage } = useGlobalContext()
  const [chatboxClose, setChatboxClose] = useState(false)

  // useEffect(() => {
  //   if (screenType && screenType == 'full-screen-avatar') setChatboxClose(true)
  // }, [screenType])

  return (
    <BaseChatBotContext.Provider
      value={{
        micState,
        setMicState,
        audioEnabled,
      }}
    >
      <Accordion
        arrowIcon={chatboxClose ? FiChevronDown : FiChevronUp}
        className={`border-primary intro-transition fixed bottom-0 z-10 w-[33vw] border-x-0 border-b-0 border-t-4 text-sm ${
          screenType && screenType != 'full-screen-avatar' ? 'left-0' : 'left-0'
        }`}
      >
        <Accordion.Panel>
          <Accordion.Title
            className="text-primary bg-white px-4 py-3 font-bold focus:outline-none focus:ring-0"
            onClick={() => setChatboxClose(!chatboxClose)}
          >
            <div className="flex flex-row items-center justify-start space-x-2 w-full">
              <img src="assets/chat.png" alt="Chat" sizes="24" />
              <span className="flex flex-1 w-full">Conversation with Totogi</span>
            </div>
          </Accordion.Title>
          <Accordion.Content className="bg-secondary w-full border-none text-black" hidden={chatboxClose}>
            <Chatbot parse={handleUserMessage} />
          </Accordion.Content>
        </Accordion.Panel>
      </Accordion>
      <Avatar avatarType={avatarType} avatarData={avatarData} />
    </BaseChatBotContext.Provider>
  )
}

export * from './types'
