import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ChatWebSocket } from '../chat-web-socket'
import { AvatarType } from '../base-chatbot'

import { BaseChatBot, MicState } from '../base-chatbot'
import { REACT_APP_BACKEND_WEBSOCKET_URL, DEFAULT_SCRIPT_MODE } from '../../constants'
import HeygenService from '../../services/HeygenService'
import { useChatbot } from '../base-chatbot/Chatbot/ChatbotProvider'
import { useGlobalContext } from '../../store/GlobalContext'
import { toast } from 'react-toastify'

const getSentences = (val: string) => {
  return val.split(/(?<=[.!?])\s+(?=\p{Lu})/u)
}

const combineShortSentences = (strings: string[]) => {
  return strings.reduce((acc: any, val) => {
    if (acc.length && acc[acc.length - 1].split(' ').length <= 3) acc[acc.length - 1] += ' ' + val
    else acc.push(val)
    return acc
  }, [])
}

const removeBrackets = (val: string) => {
  return val.replace(/\(.*?\)|\[.*?]|{.*?}|<.*?>/g, '')
}

const fixHeygenPronunciation = (val: string) => {
  return val.replace(/totogi/gi, 'Tatogi ').replace(',000,000', ' million')
}

export const BSSBuilderChatBot = () => {
  const [webSocketReady, setWebSocketReady] = useState(false)
  const [micState, setMicState] = useState(MicState.ON)
  const currentMessageId = useRef<string>()
  const isMidStream = useRef(false)
  const inRetryValidation = useRef(false)
  let speechBusyTime = 350
  let messageCount = 0
  const wsMessageQueue: MessageEvent[] = useMemo(() => [], [])

  // iframe vars intialization
  const {
    uiPort,
    setUiPort,
    setScreenType,
    setScreenProps,
    openModal,
    setOpenModal,
    setLoadingText,
    messageToSendOverWebSocket,
    setMessageToSendOverWebSocket,
    setUiJson,
    sessionIdRef,
  } = useGlobalContext()

  // Heygen service
  const updateStatus = useCallback((message: string, toastType?: string) => {
    console.debug(message)
    if (!toastType) return
    switch (toastType) {
      case 'info':
        toast.info(message)
        break
      case 'success':
        toast.success(message)
        break
      case 'warn':
        toast.warn(message)
        break
      case 'error':
        toast.error(message)
        break
      default:
        toast(message)
    }
  }, [])
  const heygenService = useMemo(() => new HeygenService(updateStatus), [updateStatus])

  // Chatbot vars
  const { addBotMessage, clearBotMessage, setIsLoading } = useChatbot()

  const handleSocketOpen = useCallback(
    (event: Event) => {
      console.log('WebSocket connection established', event)
      setWebSocketReady(true)
    },
    [setWebSocketReady]
  )

  const handleSocketClose = useCallback(
    (event: CloseEvent) => {
      console.log('WebSocket Closed', event)
      setWebSocketReady(false)
    },
    [setWebSocketReady]
  )

  const handleSocketError = useCallback((event: Event) => {
    console.log('Error in WebSocket', event)
  }, [])

  const processWebSocketMessage = async (event: MessageEvent) => {
    const data = JSON.parse(event.data)
    // console.log(`Got WS Message: ${event.data}, current sessionId is ${sessionIdRef.current}, messageId is ${currentMessageId.current}`)
    if (data.atconnect && data.session_id) {
      console.log(`Setting session_id to ${data.session_id}`)
      sessionIdRef.current = data.session_id
      setMessageToSendOverWebSocket &&
        setMessageToSendOverWebSocket({
          type: 'init_msg',
          session_id: data.session_id,
          data: { mode: localStorage.getItem('scriptMode') ?? DEFAULT_SCRIPT_MODE },
        })
    } else if (data.session_id && data.session_id != sessionIdRef.current) {
      // Ignore messages with a different session_id
      console.log(`Ignoring message with different session_id (${data.session_id} <==> ${sessionIdRef.current})`)
    } else if (data.atmsgstart) {
      inRetryValidation.current = false
      isMidStream.current = true
      currentMessageId.current = data.message_id
      clearBotMessage(data.message_id)
    } else if ((data.message_id && data.message_id != currentMessageId.current) || !isMidStream.current) {
      // Ignore messages with an outdated message id or if isMidStream is false
      console.log('Ignoring message...')
    } else if (data.atmsgend) {
      isMidStream.current = false
    } else if (data.atmsg) {
      // Update UiConfig vars
      setUiJson && setUiJson({ data: data.data })
      const screenData = data.data.screen
      if (screenData) {
        if ((screenData.modal ?? false) && setOpenModal) setOpenModal(screenData.modal)
        else if (!!openModal?.type && setOpenModal) setOpenModal({ type: undefined, props: {} })
        if (screenData.rerenderScreen ?? true) {
          if (screenData.type && setScreenType) {
            setScreenType(screenData.type)
            if (screenData.type == 'app' && uiPort == undefined && setUiPort) setUiPort(data.data.ui_port)
          }
          if (screenData.props && setScreenProps) setScreenProps({ ...screenData.props })
          if (screenData.loadingScreenText && setLoadingText) setLoadingText(screenData.loadingScreenText)
        }
      }

      if (data.atmsg || data.atmsg.trim() != '') {
        if (inRetryValidation.current) {
          inRetryValidation.current = false
          clearBotMessage(data.message_id)
        }

        if (data.data.shouldSpeak ?? true) {
          const cleanedSentences = combineShortSentences(getSentences(data.atmsg))
          for (const sentence of cleanedSentences) {
            const t = performance.now()
            const heygenResponse = await heygenService.addMessage(fixHeygenPronunciation(removeBrackets(sentence)))
            console.debug('DEBUG: Heygen response time: ' + (performance.now() - t) + 'ms for message', sentence)
            if (heygenResponse && heygenResponse.code == 100) {
              // Include previously calculated speechBusyTime & heygen conversion delay. Reduce the network delay per request made estimated to be about 1s
              addBotMessage(
                sentence,
                data.message_id,
                Date.now() + heygenResponse.data.duration_ms * 0.5 + 350 + speechBusyTime - messageCount * 1000
              )
              // Heygen additional delay time of 350ms over and above the heygenResponseTime for better syncing of audio and text.
              speechBusyTime += heygenResponse.data.duration_ms
              // Increase counter to adjust network delay based on requests already made
              messageCount += 1
            } else addBotMessage(sentence, data.message_id)
          }
        } else addBotMessage(data.atmsg, data.message_id)
      }
      
      if (data.data.disableLoadingIndicator) setTimeout(() => setIsLoading(false), Math.max(0, speechBusyTime - 350))
    } else if (data.atretry) {
      // inRetryValidation.current = true
      // const retryCount = data.atretry
      // clearBotMessage(data.message_id)
      // if (retryCount === 3) streamSentence('Our servers are overloaded right now... please try again later.', data.message_id)
      // else if (retryCount < 3)
      //   streamSentence(
      //     `Our servers are ${retryCount === 1 ? 'busy right now...' : 'still busy...'} I'll try again for you in ${
      //       retryCount * 20
      //     } seconds.`,
      //     data.message_id
      //   )
    }
  }

  const processWebSocketMessages = async () => {
    while (wsMessageQueue.length > 0) {
      await processWebSocketMessage(wsMessageQueue[0])
      wsMessageQueue.shift()
    }
  }

  const handleWebSocketMessage = (event: MessageEvent) => {
    wsMessageQueue.push(event)
    if (wsMessageQueue.length === 1) {
      speechBusyTime = 0
      messageCount = 0
      processWebSocketMessages()
    }
  }

  return (
    <>
      <ChatWebSocket
        url={REACT_APP_BACKEND_WEBSOCKET_URL}
        onOpen={handleSocketOpen}
        onMessage={handleWebSocketMessage}
        onClose={handleSocketClose}
        onError={handleSocketError}
        messageToSend={messageToSendOverWebSocket}
        heartbeatMs={30000}
      />
      <BaseChatBot
        avatarType={AvatarType.HEYGEN}
        avatarData={{ heygen: { heygenService } }}
        micState={micState}
        setMicState={setMicState}
        audioEnabled={true}
      />
    </>
  )
}
