import React, { useState, useEffect } from 'react'

const LoadingMessage = () => {
  const [dots, setDots] = useState('.')

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDots((prevDots) => {
        if (prevDots.split(' ').length >= 3) {
          return '.'
        } else {
          return prevDots + ' .'
        }
      })
    }, 1000)
    return () => clearInterval(intervalId)
  }, [])

  return (
    <div className="flex justify-start">
      <div className="my-2 inline-block max-w-[80%] rounded-3xl bg-white px-3 py-2">{dots}</div>
    </div>
  )
}

export default LoadingMessage
