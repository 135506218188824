import axios from 'axios'
import { DEFAULT_AVATAR, HEYGEN_PING_INTERVAL } from '../constants'

export default class HeygenService {
  constructor(updateStatusCallback) {
    console.debug('Instantiated Heygen Service')
    this.init(updateStatusCallback)

    // Heartbeat every 2 mins
    setInterval(() => {
      if (this.messages.length == 0) {
        this.addMessage('.')
        console.debug('Sent Heygen ping')
      }
    }, HEYGEN_PING_INTERVAL)
  }

  init(updateStatusCallback) {
    this.messages = []
    this.isProcessing = false
    this.api = axios.create({
      baseURL: 'https://api.heygen.com',
      headers: { 'Content-Type': 'application/json', 'X-Api-Key': localStorage.getItem('heygen_key') },
    })
    this.avatarRef = null
    this.sessionInfo = null
    this.sessionOpen = false
    this.peerConnection = new RTCPeerConnection({ iceServers: [] })
    this.updateStatus = updateStatusCallback
  }

  // Enqueue TTS message with Heygen Avatar
  async addMessage(message) {
    this.messages.push(message)
    if (this.sessionOpen) return await this.repeat(message)
    // if (!this.isProcessing && this.sessionOpen) this.processMessages()
  }

  // Automatically process enqueued messages sequentially
  async processMessages() {
    if (this.isProcessing || !this.sessionOpen) return
    this.isProcessing = true
    while (this.messages.length > 0) {
      const message = this.messages.shift()
      await this.repeat(message)
    }
    this.isProcessing = false
  }

  // Setup peer connection with Heygen
  async setupHeygenSession() {
    try {
      // Close existing session if any
      if (localStorage.getItem('heygen_session')) await this.stopSession()
      const avatarName = localStorage.getItem('avatarName') ?? DEFAULT_AVATAR
      const voice_id = '5e149e856da743dc8333844f2fa3e802'
      // Setup a new session
      this.updateStatus('Summoning the avatar', 'info')
      this.sessionInfo = await this.newSession('high', avatarName, voice_id)
      const { sdp: serverSdp, ice_servers: iceServers } = this.sessionInfo
      localStorage.setItem('heygen_session', JSON.stringify(this.sessionInfo))
      this.peerConnection.setConfiguration({
        iceServers: iceServers.map((server) => ({ urls: server })),
      })
      this.peerConnection.onicecandidate = ({ candidate }) => {
        if (candidate) this.handleICE(candidate.toJSON())
      }
      this.peerConnection.oniceconnectionstatechange = () => {
        const connectionStatus = this.peerConnection.iceConnectionState
        if (['disconnected', 'failed', 'closed'].includes(connectionStatus)) {
          console.log('disconnected')
          this.avatarRef.current.pause()
        }
        this.updateStatus(`ICE connection state changed to: ${this.peerConnection.iceConnectionState}`)
      }
      this.peerConnection.ontrack = (event) => {
        if (event.track.kind === 'audio' || event.track.kind === 'video') {
          if (this.avatarRef.current) {
            console.debug(`Setting ${event.track.kind} to video ref`)
            this.avatarRef.current.srcObject = event.streams[0]
          }
        }
      }
      await this.peerConnection.setRemoteDescription(new RTCSessionDescription(serverSdp))
      const localDescription = await this.peerConnection.createAnswer()
      await this.peerConnection.setLocalDescription(localDescription)
      await this.startSession(localDescription)
      this.sessionOpen = true
      this.updateStatus('Session setup completed')
      // Process existing queue messages, if any
      if (this.sessionOpen && !this.isProcessing && this.messages.length) setTimeout(() => this.processMessages(), 1000)
    } catch (error) {
      console.error('Error setting up session:', error.message)
      this.updateStatus('Status 5435', 'error')
    }
  }

  // Close the peer connection with Heygen
  async closeHeygenSession() {
    if (!this.sessionOpen) {
      this.updateStatus('Setup a session before closing', 'warn')
    } else {
      this.peerConnection.close()
      await this.stopSession()
    }
  }

  // Create a new session with Heygen
  async newSession(quality, avatar_name, voice_id) {
    try {
      const response = await this.api.post('/v1/realtime.new', {
        quality,
        avatar_name,
        voice: { voice_id },
      })
      if (response.status === 500) {
        console.error('Server error')
        this.updateStatus('Server Error. Please ask the staff if the service has been turned on')
        this.updateStatus('Status 5432', 'error')
      } else if (response.status == 400) {
        console.error('Bad Request', response.data)
        this.updateStatus(`Session failed to start: ${JSON.stringify(response.data)}`)
        this.updateStatus('Status 5433', 'error')
      } else {
        const data = response.data
        this.updateStatus(`Session created successfully: ${JSON.stringify(data.data.session_id)}`)
        return data.data
      }
    } catch (error) {
      console.error('Error creating new session:', error.message)
      this.updateStatus('Error creating new session. Please try again.')
    }
  }

  // Start the session and display audio/video
  async startSession(sdp) {
    try {
      this.updateStatus(`Starting session: ${this.sessionInfo.session_id}`)
      const response = await this.api.post('/v1/realtime.start', {
        session_id: this.sessionInfo.session_id,
        sdp,
      })
      if (response.status === 500) {
        console.error('Server error')
        this.updateStatus('Server Error. Please ask the staff if the service has been turned on')
        this.updateStatus('Status 5432', 'error')
      } else {
        const data = response.data
        this.updateStatus(`Session started successfully: ${JSON.stringify(data)}`)
        return data
      }
    } catch (error) {
      console.error('Error starting session:', error.message)
      this.updateStatus('Error starting session. Please try again.')
    }
  }

  // Handle ICE candidates
  async handleICE(candidate) {
    try {
      this.updateStatus(`Started signalling: ${this.sessionInfo.session_id}`)
      const response = await this.api.post('/v1/realtime.ice', {
        session_id: this.sessionInfo.session_id,
        candidate,
      })
      if (response.status === 500) {
        console.error('Server error')
        this.updateStatus('Server Error. Please ask the staff if the service has been turned on')
        this.updateStatus('Status 5432', 'error')
      } else {
        const data = response.data
        this.updateStatus(`Signalling successful: ${JSON.stringify(data)}`)
        return data
      }
    } catch (error) {
      console.error('Error handling ICE candidate:', error.message)
      this.updateStatus('Error handling ICE candidate. Please try again.')
    }
  }

  // Repeat the text
  async repeat(text) {
    try {
      this.updateStatus(`Session_id: ${this.sessionInfo.session_id}. Repeating text: ${text}`)
      const task_type = 'repeat'
      const response = await this.api.post('/v1/realtime.task', {
        session_id: this.sessionInfo.session_id,
        text,
        task_type,
      })

      if (response.status === 500) {
        console.error('Server error')
        this.updateStatus('Server Error. Please ask the staff if the service has been turned on')
        this.updateStatus('Status 5432', 'error')
      } else {
        const data = response.data
        this.updateStatus(`Receiving repeat response: ${JSON.stringify(data)}`)
        return data
      }
    } catch (error) {
      console.error('Error repeating text:', error.message)
      this.updateStatus('Error repeating text. Please try again.')
      this.updateStatus('Status 5434', 'error')
    }
  }

  // Stop session
  async stopSession() {
    try {
      const response = await this.api.post('/v1/realtime.stop', {
        session_id: this.sessionInfo?.session_id ?? JSON.parse(localStorage.getItem('heygen_session'))?.session_id,
      })
      if (response.status === 500) {
        console.error('Server error')
        this.updateStatus('Server Error. Please ask the staff for help')
        this.updateStatus('Status 5432', 'error')
      } else {
        const data = response.data
        this.updateStatus(`Session closed successfully: ${JSON.stringify(data)}`)
        // this.init(this.updateStatus)
        localStorage.removeItem('heygen_session')
        return data
      }
    } catch (error) {
      console.error('Error stopping session:', error.message)
      this.updateStatus('Error stopping session. Please try again.')
      this.updateStatus('Status 5436', 'error')
    }
  }
}
