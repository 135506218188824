import React from 'react'
import { MdAdd } from 'react-icons/md'
import SlideHeading from './SlideHeading'
import CircleSelect from './CircleSelect'

const renderActionsCard = (item: any, index: number) => {
  return (
    <div
      key={index}
      className={`relative shadow-lg bg-gray-100 flex flex-col justify-stretch items-start w-full rounded-md ${
        item.selected && 'select-item'
      }`}
    >
      <CircleSelect isSelected={item.selected} />
      <div className="flex flex-row items-center justify-start w-full h-full py-2 px-4 rounded-t-md bg-[#013D45] space-x-2">
        <img src={item?.icon} className="!w-8 !h-8 aspect-auto object-contain p-1 bg-white !rounded-full" alt={item?.icon} />
        <img src={item?.wordmark} className="!w-20 aspect-auto object-contain" alt={item?.wordmark} />
      </div>
      <div className="flex flex-1 px-4 py-8 font-normal text-xl">{item?.value}</div>
    </div>
  )
}

const renderImageCard = (item: any, index: number) => {
  return (
    <img
      src={item.value}
      alt={item.alt ?? ''}
      key={index}
      className={`!w-[20%] !h-[20%] relative aspect-auto object-contain bg-white !border !border-black !border-solid !rounded-2xl p-2 hover:bg-gray-200 ${
        item.selected && 'select-item'
      }`}
    >
      <CircleSelect isSelected={item.selected} />
    </img>
  )
}

const renderBulletItem = (item: any, index: number) => {
  return (
    <li key={index} className="animate-slide opacity-0 text-left" style={{ animationDelay: `${7 + index * 2}s` }}>
      {item.value}
    </li>
  )
}

export const Actions = ({ content, heading }: any) => {
  let extra_card
  if (content.length) {
    extra_card = { icon: content[0].icon, wordmark: content[0].wordmark }
  }

  return (
    <div className="flex flex-col w-full h-full items-center space-y-8 p-8">
      <SlideHeading heading={heading} />
      <div className="grid grid-cols-3 gap-x-4 gap-y-8 w-full h-fit text-3xl font-semibold">
        {content?.map((item: any, index: number) => {
          switch (item.type) {
            case 'image':
              return renderImageCard(item, index)
            case 'card':
              return renderActionsCard(item, index)
            case 'bullet':
              return renderBulletItem(item, index)
            default:
              return <></>
          }
        })}
        {extra_card && (
          <div className="relative shadow-lg bg-gray-100 flex flex-col justify-stretch items-center w-full rounded-md ">
            <div className="flex flex-row items-center justify-start w-full h-full py-2 px-4 rounded-t-md bg-[#013D45] space-x-2">
              <img
                src={extra_card.icon}
                className="!w-8 !h-8 aspect-auto object-contain p-1 bg-white !rounded-full"
                alt={extra_card.icon}
              />
              <img src={extra_card.wordmark} className="!w-20 aspect-auto object-contain" alt="" />
            </div>
            <div className="flex flex-row items-center space-x-2 flex-1 px-4 py-8 font-normal text-xl justify-start rounded-b-md w-full">
              <MdAdd size={24} />
              <div>Custom integration...</div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Actions
