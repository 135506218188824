import React, { useCallback, useEffect, useMemo } from 'react'
import { useChatbot } from '../Chatbot/ChatbotProvider'
import { useGlobalContext } from '../../../store/GlobalContext'
import { DeepgramService } from '../../../services/DeepgramService'

export const Mic = () => {
  const { addUserMessage, isLoading, isRecording, setIsRecording } = useChatbot()
  const { handleUserMessage } = useGlobalContext()
  const deepgramService = useMemo(() => new DeepgramService(), [])

  deepgramService.addTranscript = useCallback(
    (transcript: string) => {
      if (isLoading) return
      addUserMessage(transcript)
      handleUserMessage && handleUserMessage(transcript)
    },
    [addUserMessage, handleUserMessage, isLoading]
  )

  useEffect(() => {
    // Toggle mic based on isRecording
    if (isRecording) deepgramService.microphone?.resume()
    else deepgramService.microphone?.pause()
  }, [isRecording])

  useEffect(() => {
    const toggleMic = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.shiftKey && deepgramService.microphone) {
        deepgramService.toggleMicrophone()
        if (deepgramService.microphone.state === 'recording') setIsRecording(true)
        else setIsRecording(false)
      }
    }

    deepgramService.setup()
    document.addEventListener('keydown', toggleMic)

    return () => {
      deepgramService.cleanup()
      document.removeEventListener('keydown', toggleMic)
    }
  }, [])

  return <></>
}
