import React, { Dispatch, SetStateAction, memo } from 'react'
import { ModalContext, defaultModalState } from '../store/GlobalContext'
import Slides from './slides'
import { Modal } from 'flowbite-react'

interface ModalProps {
  modalState: ModalContext | undefined
  setModalState: Dispatch<SetStateAction<ModalContext>> | undefined
  modalUrl: string | undefined
}

const MainModal = ({ modalState, setModalState, modalUrl }: ModalProps) => {
  const ModalContent = () => {
    switch (modalState?.type) {
      case 'app':
        return (
          <div className="!h-[90vh] !w-full overflow-hidden">
            <iframe src={`${modalUrl}?embedId=modal#preview`} className="aspect-auto !h-full !w-full !m-0 rounded-lg" />
          </div>
        )
      case 'slides':
        return (
          <div className="sm:md:h-[22rem] lg:h-[25rem] xl:h-[38rem] 2xl:h-[42rem] w-full overflow-hidden">
            <Slides screenProps={modalState?.props} />
          </div>
        )
      default:
        return <></>
    }
  }

  return (
    <Modal show={!!modalState?.type} onClose={() => setModalState && setModalState(defaultModalState)} size="custom" dismissible>
      <Modal.Body className="p-0">
        <ModalContent />
      </Modal.Body>
    </Modal>
  )
}
const MemoizedModal = memo(MainModal)

export default MemoizedModal
