import React from 'react'

const ListItems = ({ content }: any) => {
  return (
    <ul className="flex-col space-y-8 flex list-disc list-inside items-start justify-center w-full h-full text-3xl font-semibold rounded-lg">
      {content?.map((item: any, index: number) => {
        return item.type == 'image' ? (
          <img
            src={item.value}
            alt={item.alt ?? ''}
            key={index}
            className="!w-[20%] !h-[20%] aspect-auto object-contain bg-white !border !border-black !border-solid !rounded-2xl p-2 hover:bg-gray-200"
          />
        ) : (
          <li key={index} className="animate-slide opacity-0 text-left" style={{ animationDelay: `${7 + index * 2}s` }}>
            {item.value}
          </li>
        )
      })}
    </ul>
  )
}

export default ListItems
