import React, { useContext, useState } from 'react'
import { MdOutlineCall } from 'react-icons/md'
import { BsChatLeftDots } from 'react-icons/bs'
import { MdOutlineSignalCellularAlt } from 'react-icons/md'
import Typewriter from 'react-ts-typewriter'
import { MdOutlineVolunteerActivism } from 'react-icons/md'
import { MdDirectionsWalk } from 'react-icons/md'
import { TbPlant } from 'react-icons/tb'
import { useGlobalContext } from '../../store/GlobalContext'

const Placeholder = () => {
  return <div className="animate-pulse h-7 w-24 bg-slate-200 flex flex-1"></div>
}

const IconMapping = [TbPlant, MdDirectionsWalk, MdOutlineVolunteerActivism]
const CurrencyMapping: Record<string, string> = { USD: '$' }

const PlanCard = (data: any, index: number) => {
  const { screenProps } = useGlobalContext()
  const [loading, setLoading] = useState(screenProps?.streamContent ?? true)
  const BgIcon = IconMapping[index]

  return (
    <div className="flex flex-col flex-1 h-[84%] shadow-md rounded-lg" key={index}>
      <div className="relative bg-theme-primary p-6 text-white text-left font-bold text-xl rounded-t-lg overflow-clip line-clamp-1 truncate">
        <BgIcon className="absolute text-theme-secondary -top-2 -right-6 z-0 h-24 w-24" />
        <div className="relative z-10">{data.planName}</div>
      </div>
      <div className="flex flex-col h-full items-start justify-between p-8">
        <div className="flex flex-row w-full space-x-1 items-baseline py-4 border-b-2 border-[#EDF7ED]">
          <span className="text-3xl font-extrabold self-start">{CurrencyMapping[data.currency]}</span>
          <span className="text-5xl font-extrabold align-middle">{data.price}</span>
          <span className="text-base font-bold self-end">/month</span>
        </div>
        <div className="flex flex-col items-start justify-between h-[60%] my-8">
          <div className="text-gray-500 text-lg text-left flex flex-1">
            {loading ? (
              <Typewriter text={data.description} cursor={false} delay={0} onFinished={() => setLoading(false)} />
            ) : (
              data.description
            )}
          </div>
          <div className="flex flex-col items-start space-y-2 mt-4">
            <div className="flex flex-row items-center text-base font-medium space-x-2">
              <MdOutlineCall size={16} />
              {loading ? <Placeholder /> : <div>{data.voice} calls</div>}
            </div>
            <div className="flex flex-row items-center text-base font-medium space-x-2">
              <BsChatLeftDots size={16} />
              {loading ? <Placeholder /> : <div>{data.voice} messages</div>}
            </div>
            <div className="flex flex-row items-center text-base font-medium space-x-2">
              <MdOutlineSignalCellularAlt size={16} />
              {loading ? <Placeholder /> : <div>{data.data} data</div>}
            </div>
          </div>
        </div>
        <div className="flex flex-col items-start space-y-1 text-sm">
          <a href="#" className="underline text-theme-primary hover:text-blue-800 visited:text-purple-600 break-words">
            Legal terms
          </a>
          <a href="#" className="underline text-theme-primary hover:text-blue-800 visited:text-purple-600 break-words">
            Banner ad
          </a>
          <a href="#" className="underline text-theme-primary hover:text-blue-800 visited:text-purple-600 break-words">
            Website copy
          </a>
        </div>
      </div>
    </div>
  )
}

const PlansView = ({ content }: any) => {
  return (
    <div className="flex flex-row h-full w-full justify-center items-center p-4 px-16 space-x-6">
      {content?.map((item: any, index: number) => PlanCard(item, index))}
    </div>
  )
}

export default PlansView
