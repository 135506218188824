import React, { createContext, Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { IS_PROD, PORT_MAPPING, REACT_APP_BSS_APP_UI_DOMAIN } from '../constants'

export interface PropsContext {
  url?: string
  animate?: boolean
  layout?: string
  heading?: string
  content?: any
  streamContent?: boolean
}

export interface ModalContext {
  type: string | undefined
  props: PropsContext
}

interface WebsocketClientMessageProps {
  type: string
  session_id: string
  message?: string
  data?: any
}

interface UiJsonProps {
  data: any
}

interface ContextProps {
  iframeLoaded: boolean
  screenType: string
  setScreenType: Dispatch<SetStateAction<string | undefined>>
  screenProps: PropsContext
  setScreenProps: Dispatch<SetStateAction<PropsContext>>
  uiPort: number | undefined
  setUiPort: Dispatch<SetStateAction<number | undefined>>
  openModal: ModalContext
  setOpenModal: Dispatch<SetStateAction<ModalContext>>
  loadingText: string
  setLoadingText: Dispatch<SetStateAction<string>>
  appUrl: string
  modalUrl: string
  uiJson: UiJsonProps
  setUiJson: Dispatch<SetStateAction<UiJsonProps>>
  sessionIdRef: any
  messageToSendOverWebSocket: WebsocketClientMessageProps
  setMessageToSendOverWebSocket: Dispatch<SetStateAction<WebsocketClientMessageProps>>
  handleUserMessage: (message: string) => void
}

export const GlobalContext = createContext<Partial<ContextProps>>({})

export const useGlobalContext = () => useContext(GlobalContext)

export const defaultModalState: ModalContext = { type: undefined, props: {} }

export const GlobalContextProvider = ({ children }: any) => {
  // Carousel vars
  const [openModal, setOpenModal] = useState<ModalContext>(defaultModalState)
  const [uiPort, setUiPort] = useState<number | undefined>(undefined)
  const [loadingText, setLoadingText] = useState('Contacting the AI engine...')
  const [iframeLoaded, setIframeLoaded] = useState<boolean>(false)
  const [screenType, setScreenType] = useState<string | undefined>(undefined)
  const [screenProps, setScreenProps] = useState<PropsContext>({
    url: undefined,
    animate: undefined,
    layout: undefined,
    heading: undefined,
    content: undefined,
    streamContent: undefined,
  })

  // Chatbot vars
  const [messageToSendOverWebSocket, setMessageToSendOverWebSocket] = useState<any>()
  const [uiJson, setUiJson] = useState({ data: {} })
  const sessionIdRef = useRef(null)

  const handleUserMessage = useCallback(
    (message: string) => {
      // console.log(`Sending user message ${message} on socket`)
      // addLoadingMessageToChat()
      setMessageToSendOverWebSocket({
        message: message.trim(),
        type: 'chat_msg',
        session_id: sessionIdRef.current,
        ...uiJson,
      })
    },
    [uiJson, setMessageToSendOverWebSocket]
  )

  const subdomain = useMemo(() => {
    if (uiPort) {
      const subdomains = PORT_MAPPING[String(uiPort)]
      return subdomains[Math.floor(Math.random() * subdomains.length)]
    } else return ''
  }, [uiPort])

  const appUrl = useMemo(() => {
    const new_url = `https://${IS_PROD ? subdomain : ''}.${REACT_APP_BSS_APP_UI_DOMAIN}${screenProps?.url ?? '/customer/1'}`
    console.debug('Setting app url to', new_url)
    return new_url
  }, [uiPort, subdomain, screenProps?.url])

  const modalUrl = useMemo(() => {
    const new_url = `https://${IS_PROD ? subdomain : ''}.${REACT_APP_BSS_APP_UI_DOMAIN}${openModal.props?.url ?? '/'}`
    console.debug('Setting modal url to', new_url)
    return new_url
  }, [uiPort, subdomain, openModal.props?.url])

  const isUrlReachable = async () => {
    try {
      const response = await fetch(appUrl, { method: 'HEAD' })
      return response.ok
    } catch (error) {
      return false
    }
  }

  useEffect(() => {
    let retryAttempt = 0
    const maxRetries = 5
    const retryDelay = 3000

    const tryConnectToIframe = async () => {
      // if (!subdomain.length) setSubdomain(getPortMapping(uiPort))
      console.debug(`Trying to connect to ${appUrl} for ${retryAttempt + 1} time(s)`)
      if (await isUrlReachable()) setIframeLoaded(true)
      else if (++retryAttempt < maxRetries) setTimeout(tryConnectToIframe, retryDelay)
      else console.error('Cannot connect to BSS App url')
    }
    // setIsBssLoading(true)
    uiPort && tryConnectToIframe()
    return () => setIframeLoaded(false)
  }, [uiPort])

  // Close modal on pressing Esc key
  useEffect(() => {
    // TODO: Use https://www.npmjs.com/package/keycode-js for more readable key codes
    const handleEsc = (event: any) => event.keyCode === 27 && setOpenModal(defaultModalState)
    openModal && window.addEventListener('keyup', handleEsc)
    return () => window.removeEventListener('keyup', handleEsc)
  }, [openModal])

  return (
    <GlobalContext.Provider
      value={{
        iframeLoaded,
        uiPort,
        setUiPort,
        screenType,
        setScreenType,
        screenProps,
        setScreenProps,
        openModal,
        setOpenModal,
        loadingText,
        setLoadingText,
        appUrl,
        modalUrl,
        uiJson,
        setUiJson,
        sessionIdRef,
        messageToSendOverWebSocket,
        setMessageToSendOverWebSocket,
        handleUserMessage,
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}
