import React from 'react'

const CircleSelect = ({ isSelected = false }: { isSelected: boolean }) => {
  return (
    <span
      className={`circle-animation absolute z-10 inline-flex rounded-full border-solid border-gray-500 opacity-50 top-2/3 left-1/2 origin-center transition duration-[1500ms] border-2 h-10 w-10 ${
        isSelected ? '' : 'hidden'
      }`}
    />
  )
}

export default CircleSelect
