import React from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import Home from './pages/home'
import NotFound from './pages/notfound'
import './styles.css'
import { Flowbite } from 'flowbite-react'
import customTheme from './theme'
import OptionsPage from './pages/options'
import { GlobalContextProvider } from './store/GlobalContext'
import Login from './pages/Login'
import AnimatedCursor from 'react-animated-cursor'
import Configuration from './pages/configuration'
import { MModalTest } from './components/slide-screens/ScrollingSchema'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const App = () => {
  return (
    <Flowbite theme={{ theme: customTheme }}>
      <BrowserRouter>
        <GlobalContextProvider>
          <ToastContainer
            position="top-right"
            autoClose={3000}
            limit={1}
            hideProgressBar={false}
            newestOnTop
            stacked
            closeOnClick
            pauseOnFocusLoss={false}
            draggable
            pauseOnHover
            theme="light"
          />
          <AnimatedCursor
            innerSize={0}
            innerScale={1}
            innerStyle={{}}
            outerSize={20}
            outerScale={2}
            outerAlpha={0}
            outerStyle={{
              border: '3px solid rgb(107 114 128)',
            }}
            trailingSpeed={1}
          />
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/config" element={<Configuration />} />
            <Route path="/options" element={<OptionsPage />} />
            <Route path="/" element={<Home />} />
            <Route path="/*" element={<NotFound />} />
          </Routes>
        </GlobalContextProvider>
      </BrowserRouter>
    </Flowbite>
  )
}

export default App
