import React from 'react'
import { colorMapping } from '../../constants'

const renderRow = (data: any, index: number) => {
  const { bg, border } = colorMapping[index % colorMapping.length]
  return (
    <div className="flex flex-row w-full items-stretch text-xl" key={index}>
      <div className="w-1/4 flex flex-grow items-center justify-center bg-gray-100 p-4 border border-white border-solid">{data.value}</div>
      <div
        className="w-3/4 font-light px-6 py-8 border border-white border-solid flex flex-row items-center flex-wrap gap-4"
        style={{ backgroundColor: bg }}
      >
        {data.cards.map((card: string, index: number) => (
          <div key={index} className="w-fit border-l-4 border-solid rounded-md bg-white p-4 shadow-md" style={{ borderLeftColor: border }}>
            {card}
          </div>
        ))}
      </div>
    </div>
  )
}

const BusinessFlows = ({ content }: any) => {
  return (
    <div className="flex flex-col p-6 w-full h-full overflow-y-auto">
      {content.map((item: any, index: number) => renderRow(item, index))}
    </div>
  )
}

export default BusinessFlows
