import React from 'react'

const renderCode = (item: any, index: number) => {
  const codeLines = item.value.replaceAll('bss.copilots.k8.devfactory.com', 'assets.bssmagic.totogi.solutions').split('\n')
  const processedLines = codeLines.map((line: string, idx: number) => {
    const makePurple = line.endsWith('#makepurple')
    const processedLine = makePurple ? line.replace('#makepurple', '') : line
    return (
      <div className={makePurple ? 'text-purple-400' : ''} key={idx}>
        {processedLine ? processedLine : <br />}
      </div>
    )
  })

  return (
    <code key={index}>
      {item.path}
      {'\n'}
      {processedLines}
    </code>
  )
}

const ScrollingCode = ({ content }: any) => {
  return (
    <div className="flex-col flex items-start justify-start w-full h-full">
      <div className="bg-gray-700 border border-solid border-gray-900 py-2 px-4 w-full mr-auto opacity-100 text-white relative z-10">
        Code
      </div>
      <div className="bg-black text-white w-full h-full aspect-auto flex flex-col items-stretch justify-stretch">
        <pre className="p-4 scroll-text text-left text-xl">
          {content?.map((item: any, index: number) => {
            return renderCode(item, index)
          })}
        </pre>
      </div>
    </div>
  )
}

export default ScrollingCode
