import React, { useEffect } from 'react'
import { BSSBuilderChatBot } from '../components/avatar'
import { Carousel as RRCarousel } from 'react-responsive-carousel'
import Slides from './slides'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { ChatbotProvider } from '../components/base-chatbot/Chatbot/ChatbotProvider'
import { useGlobalContext } from '../store/GlobalContext'
import MemoizedModal from './MemoizedModal'
import { Navigate } from 'react-router-dom'

const Home = () => {
  const { screenProps, screenType, openModal, setOpenModal, appUrl, modalUrl, iframeLoaded } = useGlobalContext()
  const isAuthenticated = localStorage.getItem('user')
  if (!isAuthenticated) return <Navigate to="/login" />

  useEffect(() => {
    const cursorListener = (e: any) => {
      if (e.data && e.data.type === 'mousemove') {
        const iframes = document.querySelectorAll('iframe')
        const iframe = Array.from(iframes).filter((ele) => new URL(ele.src).searchParams.get('embedId') === e.data.id)[0]
        const boundingClientRect = iframe?.getBoundingClientRect()
        const correctedX = e.data.x + boundingClientRect?.left
        const correctedY = e.data.y + boundingClientRect?.top
        const evt = new MouseEvent('mousemove', { bubbles: true, cancelable: true, clientX: correctedX, clientY: correctedY })
        document.dispatchEvent(evt)
      }
    }
    iframeLoaded && window.addEventListener('message', cursorListener)
    return () => window.removeEventListener('message', cursorListener)
  }, [iframeLoaded])

  return (
    <div className={`intro-transition flex max-h-screen min-h-screen h-screen w-full items-stretch justify-stretch bg-graph bg-fill`}>
      <div
        className={`intro-transition flex flex-row-reverse items-end justify-stretch ${
          screenType && screenType != 'full-screen-avatar' ? 'side-screen-avatar' : 'full-screen-avatar'
        }`}
      >
        <ChatbotProvider>
          <BSSBuilderChatBot />
        </ChatbotProvider>
      </div>
      {screenType && (
        <div
          className={`intro-transition relative flex flex-1 flex-col items-stretch justify-evenly bg-graph bg-fill ${
            screenType != 'full-screen-avatar' ? 'w-full p-8' : 'p-0'
          }`}
        >
          <RRCarousel
            selectedItem={Number(screenType == 'app')}
            showThumbs={false}
            showStatus={false}
            showIndicators={false}
            showArrows={false}
            autoFocus={false}
            autoPlay={false}
            centerMode={false}
            swipeable={false}
            emulateTouch={false}
            transitionTime={0}
            useKeyboardArrows={false}
            className="flex flex-col h-full w-full justify-stretch items-stretch"
          >
            <div className="aspect-auto h-[92vh] 2xl:h-[95vh] w-full overflow-hidden">
              <Slides screenProps={screenProps} />
            </div>
            <div className="relative">
              <div className="bg-white absolute inset-0 !h-[92vh] !w-full !m-0 rounded-xl overflow-hidden flex flex-col items-center justify-center space-y-4">
                {/* <div className="h-1/5 w-full"></div> */}
                {/* <div className="h-full w-full !m-0 flex flex-1 rounded-xl overflow-hidden bg-loader object-fill bg-center bg-no-repeat bg-contain"></div> */}
                <video className="w-full h-full !m-0 flex flex-1 overflow-hidden" muted autoPlay loop src="/spinner-white.mov" />
                {/* <div className="h-1/5 w-full font-semibold text-gray-500 text-3xl">{loadingText}</div> */}
              </div>
              {iframeLoaded && (
                <iframe
                  src={`${appUrl}?embedId=slide#preview`}
                  className="absolute inset-0 h-[92vh] !w-full !m-0 rounded-xl overflow-hidden z-1"
                />
              )}
            </div>
          </RRCarousel>
        </div>
      )}
      <MemoizedModal modalState={openModal} setModalState={setOpenModal} modalUrl={modalUrl} />
    </div>
  )
}

export default Home
